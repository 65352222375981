import React, { useMemo, useContext } from 'react';
import { Link } from 'gatsby';
import { NavigationContext } from '../../components/navigation';

function renderCategory ({ category, __typename }) {
  if (/Page$/.test(__typename)) return null;

  if (__typename === 'SolutionCategory') __typename = 'Solution';

  return (
    <h6 className="card-p">{category ?? __typename}</h6>
  );
}

export default function SearchResults ({ searchResults }) {
  const navigationMap = useContext(NavigationContext);

  const resultsRendered = useMemo(() => {
    if (!searchResults) return null;

    if (!searchResults.length) {
      return ( <li><h5 className="no-results">No results</h5></li> );
    }

    return searchResults.map((result, i) => {
      const img = result.cardBackgroundImage ?? result.heroBackgroundImage;

      return (
        <li key={i}>
          <Link className="search-result" to={navigationMap.get(result.sys.id)?.path}>
            <img src={img?.url} alt={img?.description} />
            {renderCategory(result)}
            <h5 className="card-heading">{result.title}</h5>
            <p>{result.metaDescription}</p>
          </Link>
        </li>
      );
    });
  }, [ searchResults, navigationMap ]);

  return (
    <section className="search-results container">
      <ul>{resultsRendered}</ul>
    </section>
  );
}
