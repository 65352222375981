/*global process */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import { HeroBackgroundImage } from '../../components/images';
import SearchForm from '../../components/navigation/search-form';
import SearchResults from './search-results';
import './index.scss';

const SEARCH_ENDPOINT = `${process.env.API_BASE}/search`;

export default function SearchPage ({ path, data, location: navLocation }) {
  const [ searchResults, setSearchResults ] = useState(null);
  const [ searchQuery, setSearchQuery ] = useState(null);

  const {
    title, heroHeading, modules, metaDescription, heroBackgroundImage, ogImage
  } = data.contentfulSearchPage;

  useEffect(() => {
    const locationUrlSearchParams = new URLSearchParams(navLocation.search);
    const q = locationUrlSearchParams.get('q');

    setSearchQuery(q);
  }, [ navLocation.search ]);

  useEffect(() => {
    if (!searchQuery) return;

    async function getSearchResults (q) {
      if (searchQuery?.length < 3) return [];

      const searchParams = new URLSearchParams({ q });
      const res = await fetch(`${SEARCH_ENDPOINT}?${searchParams}`, {
        headers: { Accept: 'application/json' }
      });

      if (!res.ok) {
        const errBody = await res.text();
        throw new Error(`Search error (${res.status}): ${errBody}`);
      }

      return await res.json();
    }

    getSearchResults(searchQuery).then((results) => {
      setSearchResults(results);
    });
  }, [ searchQuery ]);

  let searchResultsRendered = null;
  if (searchQuery && !searchResults) {
    searchResultsRendered = (
      <section className="search-results container">
        <ul><li><h5 className="loading">Loading...</h5></li></ul>
      </section>
    );
  } else if (searchQuery && searchResults) {
    searchResultsRendered = ( <SearchResults searchResults={searchResults} /> );
  }

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <section className="hero hero-search">
        <HeroBackgroundImage heroBackgroundImage={heroBackgroundImage} />
        <div className="container hero__inner">
          <h2>{heroHeading?.heroHeading}</h2>
        </div>
      </section>

      <SearchForm searchPage={{ path }} q={searchQuery} className="search-form container" />

      {searchResultsRendered}

      {renderModules(modules)}
    </Layout>
  );
}

export const pageQuery = graphql`
query SearchPageById($id: String!) {
  contentfulSearchPage(id: {eq: $id}) {
    title { title }
    metaDescription { metaDescription }
    heroHeading { heroHeading }

    ogImage: heroBackgroundImage {
      ...pageOgImageFields
    }

    heroBackgroundImage {
      ...heroBackgroundImageFields
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulBeyondZeroHarmModule {
        ...beyondZeroHarmModuleFields
      }
      ... on ContentfulContactModule {
        ...contactModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulDownloadModule {
        ...downloadModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulFeaturedResourcesModule {
        ...featuredResourcesModuleFields
      }
      ... on ContentfulFeaturedSolutionsModule {
        ...featuredSolutionsModuleFields
      }
      ... on ContentfulHorizontalLinksModule {
        ...horizontalLinksModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulLeadershipModule {
        ...leadershipModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }
}
`;
